import React, { useContext, useEffect, useMemo } from "react";

import { OIDCContext } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { useIntl } from "react-intl";
import { Redirect, Route, Switch } from "react-router-dom";
import { IntlProvider } from "@components/App/IntlProvider";
import { Spinner } from "@components/Common/Spinner";
import { DutyPhonesPage } from "@components/DutyPhonesPage";
import { Header } from "@components/Header";
import { NotificationsPage } from "@components/NotificationsPage";
import { PtsPage } from "@components/PtsPage";
import { UsersPage } from "@components/UsersPage";
import { getConfig } from "@di";
import { HomeContext, RootContext } from "@services/react";
import { URLS } from "@services/router";

import { ErrorMessage } from "../ErrorMessage";
import { UIAlertModal } from "../UIAlertModal";
import "./style.scss";

const PermissionDenied = () => {
  const intl = useIntl();
  return (
    <ErrorMessage
      message={intl.formatMessage({
        defaultMessage: "You have no permissions to access this page",
        description: "Permission denied error message",
      })}
    />
  );
};

export const Home = observer(() => {
  const { user, logout } = useContext(OIDCContext);
  const {
    initHomeStore,
    disposeHomeStore,
    authStore: { setUser, setLogoutCallback },
  } = useContext(RootContext);

  const homeStore = useMemo(() => {
    setLogoutCallback(logout);
    setUser(user);
    return initHomeStore();
  }, [initHomeStore]);

  useEffect(() => {
    return () => disposeHomeStore();
  }, []);

  const { uiAlert: notification, ready } = homeStore;

  if (!ready) {
    return <Spinner />;
  }

  const {
    profile: { is_admin, is_notification_admin, is_user_admin, is_pts_admin },
  } = user;

  const defaultUrl = is_user_admin
    ? URLS.USERS
    : is_notification_admin
      ? URLS.NOTIFICATIONS
      : is_pts_admin
        ? URLS.PTS
        : URLS.USERS;

  const { locales, defaultLocale } = getConfig();
  const { enableDutyPhones, enablePts } = getConfig();

  if (!is_admin) {
    return (
      <IntlProvider value={{ locales, defaultLocale }}>
        <div className={"home"}>
          <PermissionDenied />
        </div>
      </IntlProvider>
    );
  }

  return (
    <IntlProvider value={{ locales, defaultLocale }}>
      <HomeContext.Provider value={homeStore}>
        <div className={"home"}>
          <Header />
          <Switch>
            {is_user_admin && <Route path={`${URLS.USERS}/:userId?`} component={UsersPage} exact />}

            {is_notification_admin && <Route path={URLS.NOTIFICATIONS} component={NotificationsPage} exact />}

            {is_user_admin && enableDutyPhones && <Route path={URLS.DUTY_PHONES} component={DutyPhonesPage} exact />}

            {is_pts_admin && enablePts && <Route path={`${URLS.PTS}/:ptsId?`} component={PtsPage} exact />}
            <Redirect to={defaultUrl} />
          </Switch>
          {notification && <UIAlertModal />}
        </div>
      </HomeContext.Provider>
    </IntlProvider>
  );
});
