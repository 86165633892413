import React, { useContext, useEffect, useMemo, useState } from "react";

import { TableColumn, Table } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { FormattedMessage, IntlShape, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router-dom";
import { Content } from "@components/Common/Content";
import { ExportButton } from "@components/Common/ExportButton";
import { GroupHeader } from "@components/Common/GroupHeader";
import { PageToolbar } from "@components/Common/PageToolbar";
import { Spinner } from "@components/Common/Spinner";
import { getConfig } from "@di";
import { extractLocalized } from "@i18n";
import { TURN_LENGTH_LABELS, FLIGHT_STATUS_LABELS } from "@i18n/messages";
import { PtsSchedule } from "@models/pts";
import { ptsScheduleToCSV } from "@services/csv/ptsSchedule";
import { getStatusValue } from "@services/data/common";
import { exportFile } from "@services/export";
import { getReferencePointTitle } from "@services/ptsUtils";
import { cn, HomeContext } from "@services/react";
import { URLS } from "@services/router";
import { isFiniteNumber } from "@services/utils";
import { PtsOperationModalStore } from "@stores/PtsOperationModalStore";

import { PtsMainModal } from "../PtsMainModal";
import { PtsOperationModal } from "../PtsOperationModal";

import s from "./style.module.scss";

const formatInterval = (value: number | null) => {
  return isFiniteNumber(value) ? `${value / 60}m` : "-";
};

const getIntervalColumn = (key: "start" | "end") => {
  return {
    title: `${key} window`,
    render: (schedule: PtsSchedule) => {
      const { referencePoint, idealTime, orangeInterval, redInterval } = schedule[key];

      const items = [redInterval.start, orangeInterval.start, idealTime, orangeInterval.end, redInterval.end].map(
        formatInterval,
      );

      return (
        <div className={s.columnContent}>
          <span className={s.title}>
            <FormattedMessage
              defaultMessage="{title}: {time}m"
              description="pts columns"
              values={{
                title: getReferencePointTitle(referencePoint),
                time: idealTime / 60,
              }}
            />
          </span>
          <span className={s.subtitle}>
            {items.map((item, index) => (
              <span key={index}>{item}</span>
            ))}
          </span>
        </div>
      );
    },
  };
};

const getColumns = (intl: IntlShape) => {
  const { ptsLabels, enablePtsDirection } = getConfig();

  const columns: TableColumn[] = [
    {
      title: intl.formatMessage({
        defaultMessage: "Operation",
        description: "pts columns",
      }),
      render: (schedule: PtsSchedule) => extractLocalized(ptsLabels[schedule.id], schedule.id),
    },
    getIntervalColumn("start"),
    getIntervalColumn("end"),
    {
      title: intl.formatMessage({
        defaultMessage: "Continuous",
        description: "pts columns",
      }),
      width: 1,
      getCellClass: (schedule: PtsSchedule) => cn("bold", { green: schedule.uninterruptible }),
      render: (schedule: PtsSchedule) => (schedule.uninterruptible ? "Yes" : "No"),
    },
  ];

  if (enablePtsDirection) {
    columns.splice(1, 0, {
      title: intl.formatMessage({
        defaultMessage: "Direction",
        description: "pts columns",
      }),
      render: (schedule: PtsSchedule) => schedule.direction,
    });
  }

  return columns;
};

export const PtsDetailsPage = observer(() => {
  const history = useHistory();
  const { ptsId } = useParams<{ ptsId?: string }>();
  const { ptsPageStore } = useContext(HomeContext);
  const {
    initPtsList,
    ready,
    setSelectedPtsId,
    highlightItemId,
    setHighlightItem,
    onRemoveOperations,
    getPtsById,
    ptsData,
  } = ptsPageStore;

  const intl = useIntl();
  const columns = useMemo(() => getColumns(intl), []); // eslint-disable-line react-hooks/exhaustive-deps
  const [selectedNotificationsIds, setSelectedNotificationsIds] = useState<string[]>([]);
  const [selectedPtsSchedule, setSelectedPtsSchedule] = useState<PtsSchedule | null>(null);
  const pts = getPtsById(ptsId);

  useEffect(() => {
    void initPtsList();

    return () => setSelectedPtsId(null);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (ready && !pts) {
      history.push(URLS.PTS);
    }
  }, [pts]);

  if (!ready) {
    return <Spinner />;
  }

  if (!pts) {
    return null;
  }

  const newPtsSchedule = PtsOperationModalStore.createNewPtsSchedule(pts);
  const { inboundFlightStatus, outboundFlightStatus } = pts.filters;
  const flightStatusTitle =
    !inboundFlightStatus && !outboundFlightStatus
      ? "-"
      : (() => {
          const value = getStatusValue({
            inboundFlightStatus,
            outboundFlightStatus,
          });

          return value ? intl.formatMessage(FLIGHT_STATUS_LABELS[value]) : "-";
        })();

  const handleExport = () => {
    exportFile("pts-schedule.csv", ptsScheduleToCSV(pts.schedules));
  };

  const handleBackClick = () => {
    history.push(URLS.PTS);
  };

  return (
    <Content>
      <section className={s.ptsPage}>
        <PageToolbar className={s.header}>
          <PageToolbar.Item>
            <a onClick={handleBackClick} className={s.backBtn}>
              <i className={"fas fa-arrow-left"} />
            </a>
            <h1>
              <FormattedMessage defaultMessage="Details" description="pts page" />
            </h1>
          </PageToolbar.Item>
          <PageToolbar.Item align={"right"}>
            <ExportButton onClick={handleExport} />
          </PageToolbar.Item>
        </PageToolbar>
        <div className={s.details} onClick={() => setSelectedPtsId(pts.id)}>
          <div className={s.info}>
            <span>
              <FormattedMessage defaultMessage="Airline" description="pts page" />
            </span>
            <span>{pts.airline.roleName}</span>
            <span>
              <FormattedMessage defaultMessage="Length" description="pts page" />
            </span>
            <span>
              {pts.filters.requiredTurnaroundLength ? (
                intl.formatMessage(TURN_LENGTH_LABELS[pts.filters.requiredTurnaroundLength])
              ) : (
                <FormattedMessage defaultMessage="-" description="[ignore] pts page" />
              )}
            </span>
            <span>
              <FormattedMessage defaultMessage="Active" description="pts page" />
            </span>
            <span>
              {pts.active ? (
                <FormattedMessage defaultMessage="Yes" description="pts page" />
              ) : (
                <FormattedMessage defaultMessage="No" description="pts page" />
              )}
            </span>
            <span>
              <FormattedMessage defaultMessage="Flight status" description="pts page" />
            </span>
            <span>
              {flightStatusTitle ? (
                flightStatusTitle
              ) : (
                <FormattedMessage defaultMessage="-" description="[ignore] pts page" />
              )}
            </span>
          </div>
          <div className={s.info}>
            <span>
              <FormattedMessage defaultMessage="Description" description="pts page" />
            </span>
            <span>{pts.description || <FormattedMessage defaultMessage="-" description="[ignore] pts page" />}</span>
            <span>
              <FormattedMessage defaultMessage="Included aircrafts" description="pts page" />
            </span>
            <span>
              {pts.filters.requiredAircraftTypes.length ? (
                pts.filters.requiredAircraftTypes.join(", ")
              ) : (
                <FormattedMessage defaultMessage="-" description="[ignore] pts page" />
              )}
            </span>
            <span>
              <FormattedMessage defaultMessage="Excluded aircrafts" description="pts page" />
            </span>
            <span>
              {pts.filters.excludedAircraftTypes.length ? (
                pts.filters.excludedAircraftTypes.join(", ")
              ) : (
                <FormattedMessage defaultMessage="-" description="[ignore] pts page" />
              )}
            </span>
          </div>
        </div>

        <GroupHeader
          singularLabel={intl.formatMessage({
            defaultMessage: "operation",
            description: "pts page",
          })}
          pluralLabel={intl.formatMessage({
            defaultMessage: "operations",
            description: "pts page",
          })}
          group={intl.formatMessage({
            defaultMessage: "Operations",
            description: "pts page",
          })}
          activeItems={[]}
          selectedItems={selectedNotificationsIds}
          onAddItem={() => {
            console.debug("onAddItem", newPtsSchedule);
            setSelectedPtsSchedule(newPtsSchedule);
          }}
          onDelete={(ids) => void onRemoveOperations(pts.id, ids)}
        />

        <Table
          columns={columns}
          getRowKey={(v: PtsSchedule) => v.id}
          items={pts.schedules}
          selectedItems={selectedNotificationsIds}
          onSelect={(ids) => setSelectedNotificationsIds(ids)}
          onRowClick={(v: PtsSchedule) => setSelectedPtsSchedule(v)}
          getRowClass={() => ""}
          highlightItemId={highlightItemId}
          onHighlightEnd={() => setHighlightItem(null)}
        />

        {selectedPtsSchedule && (
          <PtsOperationModal
            ptsStore={ptsPageStore}
            pts={pts}
            schedule={selectedPtsSchedule}
            onClose={() => setSelectedPtsSchedule(null)}
          />
        )}
      </section>
      {ptsData && <PtsMainModal ptsData={ptsData} />}
    </Content>
  );
});
