import React, { useEffect, useState } from "react";

import { OIDCApp } from "@frontend/assaia-ui";
import { asValue } from "awilix";
import { observer } from "mobx-react";
import { BrowserRouter } from "react-router-dom";
import { rootApi } from "@api/rootApi";
import { Spinner } from "@components/Common/Spinner";
import { di } from "@di";
import { OpenIdConfig } from "@models/openIdConfig";
import { browserHistory } from "@services/router";

import { ErrorMessage } from "./ErrorMessage";
import { Home } from "./Home";

export const App = observer(() => {
  const [error, setError] = useState<Error | null>(null);
  const [creds, setCreds] = useState<OpenIdConfig | null>(null);

  const onError = (e: Error) => {
    setError(e);
  };

  useEffect(() => {
    rootApi
      .getInitialOpenIdCreds()
      .then((v) => {
        if (process.env.CLIENT_ID) {
          v.client_id = process.env.CLIENT_ID;
        }
        di.register({ openIdConfig: asValue(v) });
        setCreds(v);
      })
      .catch(onError);
  }, []);

  if (error) {
    // i18n out of intl context
    return <ErrorMessage showRefreshBtn />;
  }

  if (!creds) {
    return <Spinner />;
  }

  return (
    <OIDCApp
      onError={onError}
      authority={creds.authority}
      clientId={creds.client_id}
      onSigninCallback={(restoredValue) => {
        browserHistory.replace({ pathname: restoredValue });
      }}
    >
      <BrowserRouter>
        <Home />
      </BrowserRouter>
    </OIDCApp>
  );
});
