import React, { useContext, useMemo } from "react";

import {
  Icons24Trash,
  ModalCell,
  ModalIcon,
  ModalLabel,
  ModalSpacer,
  PrimaryButton,
  Switch,
  ModalTextArea,
  Input,
  ModalHeader,
  Modal,
} from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as NotificationSVG } from "@assets/notification.svg";
import { ReactComponent as DotsSVG } from "@assets/three-dots.svg";
import { getConfig } from "@di";
import { DutyPhone, NewDutyPhone } from "@models/dutyPhone";
import { HomeContext } from "@services/react";

import { ModalSmallDropdown } from "../../Common/ModalSmallDropdown";

import { DutyPhoneModalStore } from "./DutyPhoneModalStore";

import styles from "./style.module.scss";

type Props = {
  initialValue: DutyPhone | NewDutyPhone;
};

export const DutyPhoneModal = observer(({ initialValue }: Props) => {
  const { phonesMask } = getConfig();
  const { dutyPhonesPageStore } = useContext(HomeContext);
  const {
    data,
    isNewDutyPhone,
    close,
    onPhoneChanged,
    submit,
    deleteDutyPhone,
    toggleActive,
    onDescriptionChanged,
    isNumberValid,
  } = useMemo(() => new DutyPhoneModalStore(dutyPhonesPageStore, initialValue), []);
  const intl = useIntl();

  return (
    <Modal onClose={close}>
      <ModalHeader
        title={
          isNewDutyPhone
            ? intl.formatMessage({
                defaultMessage: "New number",
                description: "duty phone modal",
              })
            : intl.formatMessage({
                defaultMessage: "Edit number",
                description: "duty phone modal",
              })
        }
        onClose={close}
      >
        {!isNewDutyPhone && (
          <ModalSmallDropdown
            trigger={
              <ModalIcon onClick={() => {}}>
                <DotsSVG />
              </ModalIcon>
            }
          >
            <ModalCell
              onClick={deleteDutyPhone}
              title={
                <span className={styles.red}>
                  <FormattedMessage defaultMessage="Delete" description="Duty phone modal: delete btn" />
                </span>
              }
              leftIcon={<Icons24Trash className={styles.red} />}
            />
          </ModalSmallDropdown>
        )}
      </ModalHeader>
      <ModalCell
        title={intl.formatMessage({
          defaultMessage: "Enabled",
          description: "Duty phone modal",
        })}
        onClick={() => toggleActive(!data.active)}
        leftIcon={<NotificationSVG />}
      >
        <Switch active={data.active} />
      </ModalCell>

      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Phone number",
          description: "Duty phone modal",
        })}
      >
        <Input
          mask={phonesMask}
          alwaysShowMask
          value={data.phone}
          onChange={onPhoneChanged}
          onClear={() => onPhoneChanged("")}
          immediateOnChange
        />
      </ModalLabel>

      <ModalLabel
        title={intl.formatMessage({
          defaultMessage: "Description",
          description: "Duty phone modal",
        })}
      >
        <ModalTextArea
          value={data.description}
          placeholder={intl.formatMessage({
            defaultMessage: "Set description text for the duty phone...",
            description: "Duty phone modal description field placeholder",
          })}
          onChange={onDescriptionChanged}
          maxLength={100}
        />
      </ModalLabel>

      <ModalSpacer />

      <PrimaryButton disabled={!isNumberValid} onClick={submit}>
        <FormattedMessage defaultMessage="save" description="Duty phone modal save btn" />
      </PrimaryButton>
    </Modal>
  );
});
