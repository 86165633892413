import React from "react";

import { observer } from "mobx-react";
import { Route, Switch } from "react-router-dom";
import { URLS } from "@services/router";

import { PtsDetailsPage } from "./PtsDetailsPage";
import { PtsListPage } from "./PtsListPage";

export const PtsPage = observer(() => (
  <Switch>
    <Route path={URLS.PTS} component={PtsListPage} exact />
    <Route path={`${URLS.PTS}/:ptsId`} component={PtsDetailsPage} exact />
  </Switch>
));
