import ReactDOM from "react-dom";

import { App } from "@components/App";
import { RootContext } from "@services/react";
import { rootStore } from "@stores/RootStore";
import "./styles/index.scss";

ReactDOM.render(
  <RootContext.Provider value={rootStore}>
    <App />
  </RootContext.Provider>,
  document.getElementById("root"),
);

window._COMMIT_HASH = process.env.CI_COMMIT_SHA || "none";
