import React, { useContext, useMemo } from "react";

import { Modal } from "@frontend/assaia-ui";
import { observer } from "mobx-react";
import { ModalFilterScreen } from "@components/Common/ModalFilterScreen";
import { NewPts, Pts } from "@models/pts";
import { HomeContext } from "@services/react";
import { FiltersStore } from "@stores/FiltersStore";

import { PtsMainModalScreen } from "./PtsMainModalScreen";

type Props = {
  ptsData: NewPts | Pts;
};

export const PtsMainModal = observer(({ ptsData }: Props) => {
  const homeStore = useContext(HomeContext);
  const { ptsMainModalScreen, closeModal, setPtsMainModalScreen } = homeStore.ptsPageStore;

  // TODO: Create separate store to manage PTS modals
  const filtersStore = useMemo(() => {
    return new FiltersStore(homeStore, ptsData.filters, {
      showStandFilters: false,
      showAirlineFilters: false,
    });
  }, [ptsData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal onClose={closeModal}>
      {ptsMainModalScreen === "main" && <PtsMainModalScreen filtersStore={filtersStore} />}
      {ptsMainModalScreen === "filters" && (
        <ModalFilterScreen
          onClose={closeModal}
          onBack={() => setPtsMainModalScreen("main")}
          filtersStore={filtersStore}
        />
      )}
    </Modal>
  );
});
